import {
  Box,
  Container,
  Grid,
  InputBase,
  Slider,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../utils";
import StyledButton from "./StyledButton";
import SouthIcon from "@mui/icons-material/South";
import { styled } from "@mui/styles";
import {
  ToastNotify,
  usePresaleContract,
  useTokenContract,
  useUSDTContract,
} from "../ConnectivityAssets/hooks";
import usdt from "../images/usdt.png";
import token from "../images/token.png";
import { formatUnits, parseUnits } from "@ethersproject/units";
import Loading from "../loading";
import TimerCountDown from "../Timer";
import { presaleAddress } from "../ConnectivityAssets/environment";

const TextInput = styled(InputBase)({
  "& .MuiInputBase-input": {
    position: "relative",
    borderRadius: "5px",
    fontSize: "20px",
    color: "#ffffff",
    textAlign: "right",
    marginRight: "-8px",
    fontFamily: "Josefin Sans",
    backgroundColor: "transparent",
    padding: "10px",
    "&::-webkit-outer-spin-button": {
      WebkitAppearance: "none",
      margin: 0,
    },
    "&::-webkit-inner-spin-button": {
      WebkitAppearance: "none",
      margin: 0,
    },
  },
});
export default function Presale() {
  const matches = useMediaQuery("(max-width:600px)");
  const matches400 = useMediaQuery("(max-width:400px)");
  const { account, connect, signer } = useContext(AppContext);
  const [balanceToken, setbalanceToken] = useState(0);
  const [balanceUSDT, setbalanceUSDT] = useState(0);
  const [amountToBuy, setamountToBuy] = useState("");
  const [receivedTokens, setreceivedTokens] = useState("");
  const [loading, setloading] = useState(false);
  const [progressValue, setprogressValue] = useState(0);
  const [sold, setsold] = useState(0);
  const [totalSupply, settotalSupply] = useState(0);
  const [tokenPrice, settokenPrice] = useState(0);
  const [startTime, setstartTime] = useState(0);
  const [alertState, setAlertState] = useState({
    open: false,
    message: "",
    severity: undefined,
  });

  const presaleContract = usePresaleContract(signer);
  const tokenContract = useTokenContract(signer);
  const usdtContract = useUSDTContract(signer);

  const toLocalFormat = (val) => {
    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  useEffect(() => {
    const init = async () => {
      try {
        const dec = await tokenContract.decimals();
        let balance = await usdtContract.balanceOf(account);
        setbalanceUSDT(formatUnits(balance.toString(), 18));
        const tokens = await tokenContract.balanceOf(account);
        setbalanceToken(formatUnits(tokens.toString(), +dec));
      } catch (error) {}
    };
    if (account) {
      init();
    }
  }, [account, tokenContract, usdtContract]);
  const buyHadler = async () => {
    if (account) {
      if (!amountToBuy) {
        setAlertState({
          open: true,
          message: `Error! Please enter a amount`,
          severity: "error",
        });
      } else if (isNaN(amountToBuy) || +amountToBuy < 0) {
        setAlertState({
          open: true,
          message: `Error! Please enter a valid amount`,
          severity: "error",
        });
      } else if (+amountToBuy === 0) {
        setAlertState({
          open: true,
          message: `Error!  Please enter a valid amount`,
          severity: "error",
        });
      } else if (+amountToBuy > +balanceUSDT) {
        setAlertState({
          open: true,
          message: `Error! insufficient balance`,
          severity: "error",
        });
      } else {
        try {
          setloading(true);

          let allowance = await usdtContract.allowance(account, presaleAddress);
          let balance = await usdtContract.balanceOf(account);
          let givenAmount = parseUnits(amountToBuy.toString(), 18);
          if (givenAmount.toString() > allowance.toString()) {
            const tx = await usdtContract.approve(
              presaleAddress,
              balance.toString()
            );
            await tx.wait();
          }
          const tx = await presaleContract.buyTokenUSDT(givenAmount.toString());
          await tx.wait();
          setAlertState({
            open: true,
            message: `Transaction Successful!`,
            severity: "success",
          });
          setloading(false);
          initProgress();
        } catch (error) {
          setloading(false);
          if (error?.data?.message) {
            setAlertState({
              open: true,
              message: error?.data?.message,
              severity: "error",
            });
          } else if (error?.reason) {
            setAlertState({
              open: true,
              message: error?.reason,
              severity: "error",
            });
          } else {
            setAlertState({
              open: true,
              message: error?.message,
              severity: "error",
            });
          }
        }
      }
    } else {
      setAlertState({
        open: true,
        message: `Error! Please connect your wallet.`,
        severity: "error",
      });
    }
  };

  const initProgress = async () => {
    try {
      const dec = await tokenContract.decimals();
      const total = await presaleContract.soldToken();
      const price = await presaleContract.tokenPerUsd();
      const supply = await presaleContract.totalSupply();
      settotalSupply(toLocalFormat(formatUnits(supply.toString(), +dec)));
      let prog =
        (+formatUnits(total.toString(), +dec) /
          +formatUnits(supply.toString(), +dec)) *
        100;
      setprogressValue(+prog);
      setsold(formatUnits(total.toString(), +dec));
      settokenPrice(formatUnits(price.toString(), +dec));
      const time = await presaleContract.preSaleStartTime();
      setstartTime(+time);
    } catch (error) {}
  };
  useEffect(() => {
    initProgress();
  }, [tokenContract, presaleContract]);

  useEffect(() => {
    const calculator = async () => {
      try {
        const dec = await tokenContract.decimals();
        const amountRaisedBUSD = await presaleContract.usdtToToken(
          parseUnits(amountToBuy.toString(), 18)
        );
        setreceivedTokens(formatUnits(amountRaisedBUSD.toString(), +dec));
      } catch (error) {}
    };
    if (+amountToBuy > 0) {
      calculator();
    }
  }, [amountToBuy, tokenContract, presaleContract]);

  return (
    <Box py={10}>
      <ToastNotify alertState={alertState} setAlertState={setAlertState} />
      <Loading loading={loading} />
      <Container maxWidth="sm">
        <TimerCountDown time={+startTime} />
        <Box
          fontFamily="Josefin Sans"
          fontSize={matches ? "18px" : "20px"}
          fontWeight="400"
          textAlign="center"
          color="#000000"
          mt={5}
        >
          <span
            style={{
              marginRight: "5px",
              fontWeight: "700",
              fontSize: "25px",
              borderBottom: "2px solid #01217d",
              color: "#01217d",
            }}
          >
            Token Price
          </span>
          <br />1 USD = {parseFloat(tokenPrice).toFixed(3)} IMTC
          <br />1 IMTC = $0.004
        </Box>
        <Grid item xs={12} md={6}>
          <Box
            px={matches400 ? 1.5 : 3}
            py={3}
            mt={3}
            width="100%"
            height="100%"
            borderRadius="16px"
            boxShadow="rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"
            sx={{
              background: "#ffffff",
              color: "#000000",
            }}
          >
            <Box
              p={matches400 ? 1.2 : 2}
              width="100%"
              height="100px"
              borderRadius="12px"
              boxShadow="rgba(0, 0, 0, 0.35) 0px 5px 15px"
              sx={{
                background: "linear-gradient(180deg,#001b56 0%,#01217d 100%)",
              }}
            >
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography fontFamily="Josefin Sans" color="#ffffff">
                  From
                </Typography>
                <Box display="flex" alignItems="center">
                  <Typography
                    fontSize={matches400 ? "15px" : "18px"}
                    fontFamily="Josefin Sans"
                    color="#ffffff"
                  >
                    Balance:{" "}
                    <span style={{ fontWeight: "600" }}>
                      {parseFloat(balanceUSDT).toFixed(2)}
                    </span>
                  </Typography>
                </Box>
              </Box>
              <Box mt={1} display="flex" justifyContent="space-between">
                <Box mt={1} display="flex" alignItems="center">
                  <img
                    style={{ marginTop: "-6px" }}
                    src={usdt}
                    width="24px"
                    height="24px"
                    alt=""
                  />
                  <Typography
                    sx={{
                      marginLeft: "10px",
                      fontSize: "20px",
                      fontWeight: "bold",
                    }}
                    fontFamily="Josefin Sans"
                    color="#ffffff"
                  >
                    USDT
                  </Typography>
                </Box>
                <TextInput
                  placeholder="0.0"
                  type="number"
                  value={amountToBuy}
                  onChange={(e) => setamountToBuy(e.target.value)}
                />
              </Box>
            </Box>
            <Box my={1} display="flex" justifyContent="center">
              <Box
                px={1}
                pt={0.8}
                pb={0.2}
                borderRadius="50px"
                sx={{
                  cursor: "pointer",
                  background: "linear-gradient(180deg,#001b56 0%,#01217d 100%)",
                }}
                boxShadow="rgba(0, 0, 0, 0.35) 0px 5px 15px"
              >
                <SouthIcon style={{ fontSize: "30px", color: "#ffffff" }} />
              </Box>
            </Box>
            <Box
              p={matches400 ? 1.2 : 2}
              width="100%"
              height="100px"
              borderRadius="12px"
              boxShadow="rgba(0, 0, 0, 0.35) 0px 5px 15px"
              sx={{
                background: "linear-gradient(180deg,#001b56 0%,#01217d 100%)",
              }}
            >
              <Box display="flex" justifyContent="space-between">
                <Typography fontFamily="Josefin Sans" color="#ffffff">
                  To
                </Typography>
                <Typography
                  fontSize={matches400 ? "15px" : "18px"}
                  fontFamily="Josefin Sans"
                  color="#ffffff"
                >
                  Balance:{" "}
                  <span style={{ fontWeight: "600" }}>
                    {parseFloat(balanceToken).toFixed(2)}
                  </span>
                </Typography>
              </Box>
              <Box mt={1} display="flex" justifyContent="space-between">
                <Box mt={1} display="flex" alignItems="center">
                  <img
                    style={{ marginTop: "-6px" }}
                    src={token}
                    width="24px"
                    height="24px"
                    alt=""
                  />
                  <Typography
                    sx={{
                      marginLeft: "10px",
                      fontSize: "20px",
                      fontWeight: "bold",
                    }}
                    fontFamily="Josefin Sans"
                    color="#ffffff"
                  >
                    IMTC
                  </Typography>
                </Box>
                <Typography
                  fontFamily="Josefin Sans"
                  variant="h6"
                  mt={1}
                  color="#ffffff"
                >
                  {amountToBuy ? receivedTokens : "0.0"}
                </Typography>
              </Box>
            </Box>

            <Box mt={3}>
              {account ? (
                <StyledButton width="100%" onClick={() => buyHadler()}>
                  Buy
                </StyledButton>
              ) : (
                <StyledButton onClick={() => connect()} width="100%">
                  Connect wallet
                </StyledButton>
              )}
            </Box>
            <Box>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Box
                  sx={{
                    fontSize: "14px",
                    fontWeight: "400",
                  }}
                  fontFamily="Josefin Sans"
                  mt={2}
                  mb={-1}
                >
                  <span style={{ fontWeight: "600", marginRight: "5px" }}>
                    {parseFloat(sold).toFixed(2)}
                  </span>
                  IMTC Sold
                </Box>
                <Box
                  sx={{
                    fontSize: "14px",
                    fontWeight: "400",
                  }}
                  fontFamily="Josefin Sans"
                  mt={2}
                  mb={-1}
                >
                  Target{" "}
                  <span style={{ fontWeight: "600" }}>{totalSupply}</span>
                </Box>
              </Box>

              <Slider
                aria-label="Volume"
                value={progressValue}
                sx={{
                  width: "100%",
                  height: "18px",
                  color: "#7A7A7A",
                  "& .MuiSlider-track": {
                    border: "none",
                    backgroundColor: "transparent",
                    backgroundImage:
                      "linear-gradient(180deg,#001b56 0%,#01217d 100%)",
                  },
                  "& .MuiSlider-thumb": {
                    display: "none",
                  },
                }}
              />
            </Box>
          </Box>
        </Grid>
      </Container>
    </Box>
  );
}
