import React, { useContext } from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import { AppContext } from "../utils";
import logo from "../images/logo.png";
import StyledButton from "./StyledButton";

export default function Header() {
  const { account, connect, disconnect } = useContext(AppContext);

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      style={{
        background: "transparent",
        zIndex: "100px",
      }}
      height="92px"
      width="100%"
    >
      <Container maxWidth="lg">
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <img width="80px" src={logo} alt="" />
          {account ? (
            <StyledButton width="140px" onClick={() => disconnect()}>
              {account.slice(0, 4) + "..." + account.slice(-4)}
            </StyledButton>
          ) : (
            <StyledButton width="140px" onClick={() => connect()}>
              Connect
            </StyledButton>
          )}
        </Box>
      </Container>
    </Box>
  );
}
