import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import Web3 from "web3";
import "./App.css";
import bg from "./images/bg.jpg";
import Header from "./components/Header";
import Presale from "./components/Presale";
import NetworkChange from "./networkSwitch";
const web3 = new Web3(
  Web3.givenProvider ? Web3.givenProvider : "https://bsc-dataseed.binance.org/"
);
function App() {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    let chain = async () => {
      const chainid = await web3.eth.getChainId();
      if (chainid !== 56) {
        setOpen(true);
      }
    };
    chain();
  }, []);

  return (
    <Box
      sx={{
        background: `url(${bg})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "cover",
      }}
    >
      <NetworkChange open={open} setOpen={setOpen} />
      <Header />
      <Presale />
    </Box>
  );
}

export default App;
