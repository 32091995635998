import { Button } from "@mui/material";

export default function StyledButton({ children, ...props }) {
  return (
    <>
      <Button
        {...props}
        sx={{
          color: "#fff",
          background: "linear-gradient(180deg,#001b56 0%,#01217d 100%)",
          fontSize: "18px",
          boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
          textTransform: "capitalize",
          fontFamily: "Josefin Sans",
          borderRadius: "12px",
          width: props.width,
          "&.Mui-disabled": {
            color: "#979EA7",
          },
          "&:hover": {
            background: "linear-gradient(180deg,#01217d 0%,#001b56 100%)",
          },
        }}
      >
        {children}
      </Button>
    </>
  );
}
